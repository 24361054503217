export default {
  space: process.env.CONTENTFUL_SPACE_ID ?? '',
  environment: process.env.CONTENTFUL_ENVIRONMENT ?? 'master',
  preview: Number(process.env.CONTENTFUL_PREVIEW) === 1,
  host: `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/environments/${process.env.CONTENTFUL_ENVIRONMENT}`,
  hostRest:
    Number(process.env.CONTENTFUL_PREVIEW) === 1 ? 'preview.contentful.com' : 'cdn.contentful.com',
  accessToken:
    Number(process.env.CONTENTFUL_PREVIEW) === 1
      ? process.env.CONTENTFUL_ACCESS_TOKEN_PREVIEW ?? ''
      : process.env.CONTENTFUL_ACCESS_TOKEN ?? '',
  debugEdition: String(process.env.DEBUG_EDITION) === 'true',
  linkSpace: `https://app.contentful.com/spaces/${process.env.CONTENTFUL_SPACE_ID}/environments/${process.env.CONTENTFUL_ENVIRONMENT}/entries`,
}
