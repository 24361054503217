import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'

import { actionTypes } from './types'

export type ApiState = {
  headers:
    | {
        [key: string]: string
      }
    | Record<string, never>
}

//
// Initial state
//

const initialState: ApiState = {
  headers: {},
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setHeaders: (state, action: actionTypes.setHeaders) => {
      const { headers } = action.payload
      state.headers = headers
    },
    setHeader: (state, action: actionTypes.setHeader) => {
      const { header } = action.payload
      state.headers = { ...state.headers, ...header }
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state.api
const headers = (state: RootState) => root(state).headers

export const selectors = {
  headers,
}
