import { Saga } from 'redux-saga'
import { ActionPattern, all, call, put, takeLatest } from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import { ServiceRequestAction } from '../../helpers/ReduxHelpers'
import contact from '../../datas/services/contact'

export default class ContactSagas {
  static *contact(action: ServiceRequestAction) {
    const values = action.payload?.params

    const results: unknown = yield call(ApiSagas.mutate, contact(values))

    if (!(results as any)?.error) {
      yield put(actions.contact.contactSuccess({ data: (results as any)?.data }))
    } else {
      yield put(actions.contact.contactError({ errors: (results as any)?.error }))
    }
  }

  static *loop() {
    yield all([takeLatest(actions.contact.contactRequest as ActionPattern, this.contact as Saga)])
  }
}
