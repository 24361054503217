import { all, call } from 'redux-saga/effects'
import { MutationOptions, QueryOptions } from '@apollo/client'

import client, { defaultConfig } from '../../datas/Client'
import bugsnagClient from '../../helpers/BugsnagHelpers'

export type ApiTransformer = (data: any) => any

export default class ApiSagas {
  static *query(service: { query: QueryOptions; transformer?: (data: any) => any }): any {
    return yield ApiSagas.call(client.query, service.query, service?.transformer)
  }

  static *mutate(service: { mutation: MutationOptions; transformer?: (data: any) => any }): any {
    return yield ApiSagas.call(client.mutate, service.mutation, service?.transformer)
  }

  static *call(method: any, service: any, transformer?: (data: any) => any): any {
    let result
    try {
      result = yield call(method, {
        ...defaultConfig,
        ...service,
        context: {
          ...service?.context,
          headers: {
            ...service?.context?.headers,
          },
        },
      })
    } catch (e) {
      console.error(`ApiSagas:`, e, service?.variables)

      if (bugsnagClient) {
        bugsnagClient.addMetadata('graphQL', {
          Variables: service?.variables,
          Config: service,
        })
        bugsnagClient.notify(e)
      }

      return {
        errors: e,
      }
    }

    if (result.errors) {
      console.error(`ApiSagas:`, result.errors)
    }

    return yield call(ApiSagas.transform, result, transformer || service?.transformer)
  }

  static *transform(result: any, transformer?: ApiTransformer): any {
    if (!result.data || !transformer) {
      return result
    }

    const data = yield call(transformer, result.data as any)

    return { ...result, data }
  }

  static *loop() {
    yield all([
      //
    ])
  }
}
