import { PayloadAction } from '@reduxjs/toolkit'

export type ServiceProps = {
  pending: boolean
  success: boolean
  complete: boolean
  errors: any
  data: any
  params: any
}

export type ServiceRequestAction = PayloadAction<{ params?: any } | undefined>
export type ServiceSuccessAction = PayloadAction<{ data?: any } | undefined>
export type ServiceErrorAction = PayloadAction<{ errors?: any } | undefined>

export const getServiceProps = (): ServiceProps => ({
  pending: false,
  success: false,
  complete: false,
  errors: null,
  data: null,
  params: null,
})

export const getServiceReducers = (id: string) => ({
  request: (state: any, action: ServiceRequestAction) => {
    const params = action.payload?.params
    state[id] = {
      pending: true,
      success: false,
      complete: false,
      errors: null,
      data: null,
      params,
    }
  },
  success: (state: any, action: ServiceSuccessAction) => {
    const data = action.payload?.data
    state[id] = {
      ...state[id],
      pending: false,
      success: true,
      complete: true,
      errors: null,
      data,
    }
  },
  error: (state: any, action: ServiceErrorAction) => {
    const errors = action.payload?.errors
    state[id] = {
      ...state[id],
      pending: false,
      success: false,
      complete: true,
      data: null,
      errors,
    }
  },
  reset: (state: any) => {
    state[id] = getServiceProps()
  },
})
