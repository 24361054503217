import { all, put, takeLatest, select } from 'redux-saga/effects'

import { actions, selectors } from '..'

export default class AppSagas {
  static *init() {
    const isInit: boolean = yield select(selectors.app.isInit) as unknown

    if (!isInit) {
      yield put(actions.app.setIsInit({ isInit: true }))
    }
  }

  static *loop() {
    yield all([takeLatest(actions.app.init, this.init)])
  }
}
