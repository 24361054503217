import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { getServiceProps, getServiceReducers, ServiceProps } from '../../helpers/ReduxHelpers'

export const name = 'contact'

export type ContactState = {
  contact: ServiceProps
}
//
// Initial state
//

const initialState: ContactState = {
  contact: getServiceProps(),
}

//
// Slice (Actions & Reducers)
//

const contactService = getServiceReducers('contact')

const slice = createSlice({
  name,
  initialState,
  reducers: {
    contactRequest: contactService.request,
    contactSuccess: contactService.success,
    contactError: contactService.error,
    contactReset: contactService.reset,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[name]
const contact = (state: RootState) => root(state).contact

export const selectors = {
  contact,
}
