import * as api from './api/redux'
import * as app from './app/redux'
import * as contact from './contact/redux'

export const actions = {
  api: api.actions,
  app: app.actions,
  contact: contact.actions,
}

export const selectors = {
  api: api.selectors,
  app: app.selectors,
  contact: contact.selectors,
}

export const reducers = {
  api: api.reducer,
  app: app.reducer,
  contact: contact.reducer,
}
