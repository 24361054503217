import { all, fork } from 'redux-saga/effects'

// App
import AppSagas from './app/sagas'
import ContactSagas from './contact/sagas'

function* loop() {
  yield all([AppSagas.loop(), ContactSagas.loop()])
}

export default function* rootSaga() {
  yield fork(loop)
}
