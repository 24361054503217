import gql from 'graphql-tag'

import { mutationServiceCreator } from '../../helpers/GraphqlHelpers'

const mutation = gql`
  mutation requestContact($name: String!, $email: String!, $subject: String!, $message: String!) {
    requestContact(name: $name, email: $email, subject: $subject, message: $message) {
      name
      email
      subject
      message
      requested_at
    }
  }
`

const transformer = ({ requestContact }: any) => requestContact

type variables = {
  name: string
  email: string
  subject: string
  message: string
}

export default mutationServiceCreator<variables>(mutation, transformer)
